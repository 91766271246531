import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Header from "../components/Header/Header"
import AnnouncementBar from "../components/AnnouncementBar/AnnouncementBar"
import Hero from "../components/Hero/Hero"
import Intro from "../components/Intro/Intro"
import Features from "../components/Features/Features"
import Saying from "../components/Saying/Saying"
import GetStarted from "../components/GetStarted/GetStarted"
import WhyKitekure from "../components/WhyKitekure/WhyKitekure"
import Others from "../components/Others/Others"
import Shop from "../components/Shop/Shop"
import Faq from "../components/Faq/Faq"
import Footer from "../components/Footer/Footer"

import { graphql } from "gatsby"
import {useTranslation} from "gatsby-plugin-react-i18next"

export default function IndexPage() {
  const {t} = useTranslation();

  return (
    <Layout>
      <Seo title={t('SiteTitle', 'Kitekure - DJ・配信イベントの告知サイトを一瞬で作れるテンプレート')} />
      <AnnouncementBar />
      <Header />
      <Hero />
      <Intro />
      <Features />
      <Saying />
      <GetStarted />
      <WhyKitekure />
      <Shop />
      <Faq />
      <Others />
      <Footer />
    </Layout>
  )
}

export const query = graphql`
query($language: String!) {
  locales: allLocale(filter: {language: {eq: $language}}) {
    edges {
      node {
        ns
        data
        language
      }
    }
  }
}
`;
