import React from "react"
import * as styles from "./styles.module.scss"
import { StaticImage } from "gatsby-plugin-image"
import { useTranslation } from "gatsby-plugin-react-i18next"

export default function Others() {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <section>
        <h2>
          Made by{" "}
          <a
            href="https://twitter.com/sanographix"
            target="_blank"
            rel="noopener noreferrer"
          >
            sanographix
          </a>
        </h2>
        <nav className={styles.grid}>
          <a
            href={t("OthersIllustfolioLink", "https://illustfolio-lumina.sanographix.net/ja/")}
            target="_blank"
            rel="noopener noreferrer"
          >
            <StaticImage
              src="../../images/others-illustfolio-lumina.png"
              alt=""
              className={styles.wrapImg}
            />
            <div className={styles.itemContent}>
              <h3>Illustfolio Lumina</h3>
              <p>
                {t("OthersIllustfolio", "イラストサイトは、このテンプレートで")}
              </p>
            </div>
          </a>
          <a
            href={t("OthersTokusetsu4Link", "https://tokusetsu4.sanographix.net/")}
            target="_blank"
            rel="noopener noreferrer"
          >
            <StaticImage
              src="../../images/others-tokusetsu4.png"
              alt=""
              className={styles.wrapImg}
            />
            <div className={styles.itemContent}>
              <h3>Tokusetsu 4</h3>
              <p>
                {t(
                  "OthersTokusetsu4",
                  "同人音楽サイトを最速で作る方法"
                )}
              </p>
            </div>
          </a>
          <a
            href="https://azusa3.sanographix.net/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <StaticImage
              src="../../images/others-azusa.jpg"
              alt=""
              className={styles.wrapImg}
            />
            <div className={styles.itemContent}>
              <h3>Azusa 3</h3>
              <p>
                {t("OthersAzusa", "スライドのテンプレート探しを終わらせよう")}
              </p>
            </div>
          </a>
        </nav>
      </section>
    </div>
  );
}
