import React from "react";
import * as styles from "./styles.module.scss";
import { ButtonPrimary } from "../Button/Button";
import Logo from "../../images/kitekure-logo.svg";
import { Link, useI18next } from "gatsby-plugin-react-i18next";
import Translate from "../../images/translate.svg";

export default function Header() {
  const { languages, originalPath } = useI18next();

  return (
    <div className={styles.header}>
      <div className={styles.inner}>
        <div>
          <Link to="/">
            <img src={Logo} alt="Kitekure" className={styles.logo} />
          </Link>
        </div>
        <div className={styles.action}>
          <details className={styles.languages}>
            <summary>
              <img src={Translate} alt="" className={styles.translate} />{" "}
              <span>Language</span>
            </summary>
            <ul className={styles.languagesSelector}>
              {languages.map((lng) => (
                <li key={lng}>
                  <Link to={originalPath} language={lng}>
                    <span>{lng}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </details>
          <ButtonPrimary href="#getstarted">Download</ButtonPrimary>
        </div>
      </div>
    </div>
  );
}
