import React from "react"
import * as styles from "./styles.module.scss"

import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from 'gbimage-bridge';

import {useTranslation} from "gatsby-plugin-react-i18next"

const WhyKitekure = () => {
  // Gatsby 3 及び gatsby-plugin-image とともに、gatsby-background-image V1.5 を使おうと思った場合、次のメジャーバージョンまでは、暫定的に gbimage-bridge と呼ばれるコンパニオンパッケージを使う必要があります
  // https://www.gatsbyjs.com/plugins/gatsby-background-image/#gatsby-3--gatsby-plugin-image
  const { placeholderImage } = useStaticQuery(
    graphql`
      query {
        placeholderImage: file(relativePath: { eq: "whykitekure-bg.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 2000
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  )
  const image = getImage(placeholderImage)
  const bgImage = convertToBgImage(image)

  const {t} = useTranslation();

  return (
    <BackgroundImage {...bgImage}>
      <div className={styles.wrapper}>
        <div className={styles.inner}>
          <div className={styles.header}>
            <h2 className={styles.heading}>
              {t("WhyKitekureHeading","Kitekureを選ぶ理由")}
            </h2>
          </div>
          <div className={styles.content}>
            <p>{t("WhyKitekureP","あなたが、あなたのコミュニティに向けて、自信を持ってイベントを開催できるようにすること。Kitekureは、この唯一の目的のために開発された。")}</p>
            <div className={styles.readBlog}>
              <a
                href="https://text.sanographix.net/entry/kitekure"
                target="_blank"
                rel="noopener noreferrer"
              >
                Read blog
              </a>
            </div>
          </div>
        </div>
      </div>
    </BackgroundImage>
  )
}

export default WhyKitekure
