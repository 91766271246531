import React from "react"
import Cta from "../Cta/Cta"
import * as styles from "./styles.module.scss"
import * as VFX from 'react-vfx';

import {Trans, useTranslation} from "gatsby-plugin-react-i18next"

export default function GetStarted() {
  const {t} = useTranslation();

  return (
    <>
      <VFX.VFXProvider>
        <div className={styles.wrapper} id="getstarted">
          <div className={styles.inner}>
            <h2 className={styles.heading}>
              <VFX.VFXSpan shader="rgbShift">
                {t("FreeForever", "永久に無料")}
              </VFX.VFXSpan>
            </h2>
            <div className={styles.container}>
              <p>
                <Trans i18nKey="GetStartedP">
                  「<a href="https://sanographix.github.io/tokusetsu3/" target="_blank" rel="noopener noreferrer">Tokusetsu</a>」シリーズで8年間、告知用テンプレートを作り続けてきた@sanographixによる、DJとオーガナイザーのためのシンプルなソリューション。
                </Trans>
                </p>
              <div className={styles.ctaWrapper}>
                <Cta />
              </div>
            </div>
          </div>
          <div className={styles.marquee}>
            <span className={styles.marqueeText}>&nbsp;{t("FreeForever", "永久に無料")}</span>
            <span className={styles.marqueeText}>&nbsp;{t("FreeForever", "永久に無料")}</span>
            <span className={styles.marqueeText}>&nbsp;{t("FreeForever", "永久に無料")}</span>
            <span className={styles.marqueeText}>&nbsp;{t("FreeForever", "永久に無料")}</span>
          </div>
          <div className={styles.marqueeBottom}>
            <span className={styles.marqueeText}>&nbsp;{t("FreeForever", "永久に無料")}</span>
            <span className={styles.marqueeText}>&nbsp;{t("FreeForever", "永久に無料")}</span>
            <span className={styles.marqueeText}>&nbsp;{t("FreeForever", "永久に無料")}</span>
            <span className={styles.marqueeText}>&nbsp;{t("FreeForever", "永久に無料")}</span>
          </div>
        </div>
      </VFX.VFXProvider>
    </>
  )
}
