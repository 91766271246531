import React from "react"
import * as styles from "./styles.module.scss"
import { StaticImage } from "gatsby-plugin-image"

export default function Shop() {
  return (
    <div className={styles.wrapper} id="shop">
      <div className={styles.inner}>
        <div className={styles.header}>
          <h2 className={styles.heading}>
            GEAR SHOP
          </h2>
          <p>Check out new physical releases</p>
          <div className={styles.readBlog}>
            <a
              href="https://suzuri.jp/sanographix/omoide/115037"
              target="_blank"
              rel="noopener noreferrer"
            >
              Shop Now
            </a>
          </div>

        </div>
        <div className={styles.content}>
          <StaticImage src="../../images/gear.png" alt="Goods" />
        </div>
      </div>
    </div>
  )
}

