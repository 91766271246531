import React from "react"
import * as styles from "./styles.module.scss"
import SayingItem from "../SayingItem/SayingItem"
import Twitter from "./twitter.svg"

export default function Saying() {
  return (
    <section className={styles.section}>
      <div className={styles.media}>
        <figure>
          <blockquote cite="https://gigazine.net/news/20211103-kitekure/">
            <p>1行もコードを書いていないのに、かなり本格的な告知サイトに仕上がっています。</p>
          </blockquote>
          <figcaption>
            <cite>
              <a href="https://gigazine.net/news/20211103-kitekure/">GIGAZINE</a>
            </cite>
          </figcaption>
        </figure>
      </div>
      <div className={styles.container}>
        <div className={styles.row}>
          <div>
            <SayingItem
              name="えふとん🍣"
              id="@fton25"
              paragraph="kitekureでイベントサイト作ってみたけどマジで楽だったので今後も軽率に使いそう"
              link="https://twitter.com/fton25/status/1451009722283278336"
              time="2021-10-21 11:17:30"
              service="Twitter"
              serviceIcon={Twitter}
            />
            <SayingItem
              name="のの"
              id="@_no_no_ca_"
              paragraph="プレビューモードだけどここまで作るのに30分だったすごい"
              link="https://twitter.com/_no_no_ca_/status/1456105569748602887"
              time="2021-11-04 12:46:35"
              service="Twitter"
              serviceIcon={Twitter}
            />
          </div>
          <div>
            <SayingItem
              name="Snafkin"
              id="@DJsnafkin"
              paragraph='kitekureめっちゃ楽だ・・・・'
              link="https://twitter.com/DJsnafkin/status/1450836920162869255"
              time="2021-10-20 23:50:51"
              service="Twitter"
              serviceIcon={Twitter}
            />
            <SayingItem
              name="おやゆび"
              id="@oy2b"
              paragraph="今回こちらのKitekureを使用して作成しました〜 コーディングなしでかっこいい告知サイト作れるのでオススメです👍"
              link="https://twitter.com/oy2b/status/1460554756615671808"
              time="2021-11-16 19:26:04"
              service="Twitter"
              serviceIcon={Twitter}
            />
            <SayingItem
              name="INOMUSICA（⇔映野ごぐる）"
              id="@msbt_ss"
              paragraph="実際、デザイン自体楽しいには楽しいんですが負担がかかるしいい感じのフライヤーなかなか作れないのもありこういうサービスは助かりがすぎるのです・・・"
              link="https://twitter.com/msbt_ss/status/1447967228079403015"
              time="2021-10-13 01:47:43"
              service="Twitter"
              serviceIcon={Twitter}
            />
          </div>
          <div>
            <SayingItem
              name="【公式】東北ハードコア情報局"
              id="@tohokuhcinfo"
              paragraph="素晴らしいです。主催者の方はすぐにでも導入を検討すべきだと思います。"
              link="https://twitter.com/tohokuhcinfo/status/1456091499146268675"
              time="2021-11-04 11:50:40"
              service="Twitter"
              serviceIcon={Twitter}
            />
            <SayingItem
              name="真符"
              id="@muff_dot"
              paragraph="Spreadsheetにテキスト入力でWEB作れちゃうの強い"
              link="https://twitter.com/muff_dot/status/1448463461197910018"
              time="2021-10-14 10:39:34"
              service="Twitter"
              serviceIcon={Twitter}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

