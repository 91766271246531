import React from "react"
import * as styles from "./styles.module.scss"
import Fig1 from "../../images/feature1.mp4"
import Fig1Poster from "../../images/feature1.jpg"
import Fig2 from "../../images/feature2.mp4"
import Fig2Poster from "../../images/feature2.jpg"
import Fig3 from "../../images/feature3.mp4"
import Fig3Poster from "../../images/feature3.jpg"
import {Button} from "../Button/Button"

import {useTranslation} from "gatsby-plugin-react-i18next"

export default function Features() {
  const {t} = useTranslation();
  return (
    <>
      <div className={styles.background}>
        <div className={styles.container}>
          <section className={styles.section}>
            <div className={styles.sectionMain}>
              <h3>CREATE</h3>
              <p>{t("FeaturesCreate","次のパーティーを計画中？ あるいは、オンライン配信イベント？ どちらであっても、Kitekureならこれまでにないスピードで告知サイトを作成できる。")}</p>
              <Button href="http://kitekure-sample.netlify.app/" target="_blank" rel="noopener noreferrer">
              {t("ViewDemo", "デモページを見る")}
              </Button>
            </div>
            <div className={styles.video}>
              <video
                autoPlay
                playsInline
                muted
                loop
                poster={Fig1Poster}
                disableRemotePlayback
              >
                <source src={Fig1} type="video/mp4" />
              </video>
            </div>
          </section>
          <section className={styles.section}>
            <div className={styles.video}>
              <video
                autoPlay
                playsInline
                muted
                loop
                poster={Fig2Poster}
                disableRemotePlayback
              >
                <source src={Fig2} type="video/mp4" />
              </video>
            </div>
            <div className={styles.sectionMain}>
              <h3>BUILD</h3>
              <p>{t("FeaturesBuild", "サイト作成に自信がなくても心配は無用。使うのは、おなじみのGoogleスプレッドシート（本当です）。シートの指示に沿って内容を埋めていくと、あとはKitekureがサイトに一発変換してくれる。")}</p>
            </div>
          </section>
          <section className={styles.section}>
            <div className={styles.sectionMain}>
              <h3>SHARE</h3>
              <p>{t("FeaturesShare", "イベント情報が1ページにまとまるから、誰が見ても一目瞭然。出演者プロフィールやタイムテーブルは勿論のこと、開催時間までのカウントダウンやGoogleカレンダー追加機能など、イベントの気運を高める機能も多数搭載。")}</p>
            </div>
            <div className={styles.video}>
              <video
                autoPlay
                playsInline
                muted
                loop
                poster={Fig3Poster}
                disableRemotePlayback
              >
                <source src={Fig3} type="video/mp4" />
              </video>
            </div>
          </section>
        </div>
      </div>
    </>
  )
}
