import React from "react"
import * as styles from "./styles.module.scss"
import {useTranslation} from "gatsby-plugin-react-i18next"

export default function Intro() {
  const {t} = useTranslation();
  return (
    <>
      <header className={styles.header}>
        <div className={styles.container}>
          <h2>{t("IntroHeader","次のパーティーは、一味違う告知をキメよう")}</h2>
          <p>{t("IntroP","Kitekureは、イベントを成功させたいDJとオーガナイザーのために作られた、告知支援テンプレート。1行もコードを書くことなく、瞬時にイベント告知サイトを構築。永久に無料。")}</p>
        </div>
      </header>
    </>
  )
}

