import React from "react";
import * as styles from "./styles.module.scss";
import twitterIcon from "./twitter.svg";
import facebookIcon from "./facebook.svg";
import { useTranslation } from "gatsby-plugin-react-i18next";

class Cta extends React.Component {
  // シェアボタン押したかどうかの状態を持つ
  // ref. https://ja.reactjs.org/docs/conditional-rendering.html
  constructor(props) {
    super(props);
    this.shareClick = this.shareClick.bind(this);
    this.backClick = this.backClick.bind(this);
    this.state = { isShared: false };
  }

  shareClick() {
    this.setState({ isShared: true });
  }

  backClick() {
    this.setState({ isShared: false });
  }

  render() {
    // シェアボタン押したらコンポーネント出し分け
    const isShared = this.state.isShared;
    let dropdownContent;
    if (isShared) {
      dropdownContent = <Thankyou onClick={this.backClick} />;
    } else {
      dropdownContent = <ShareButtons onClick={this.shareClick} />;
    }

    // CTAボタン
    return (
      <>
        <details className={styles.cta}>
          <summary>FREE DOWNLOAD</summary>
          <div className={styles.dropdown}>{dropdownContent}</div>
        </details>
      </>
    );
  }
}

function ShareButtons(props) {
  const { t } = useTranslation();
  return (
    <>
      <ul className={styles.share}>
        <li>
          <a
            href={t(
              "TwitterShare",
              "https://twitter.com/intent/tweet?text=Kitekure+-+DJ%E3%83%BB%E9%85%8D%E4%BF%A1%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88%E3%81%AE%E5%91%8A%E7%9F%A5%E3%82%B5%E3%82%A4%E3%83%88%E3%82%92%E4%B8%80%E7%9E%AC%E3%81%A7%E4%BD%9C%E3%82%8C%E3%82%8B%E7%84%A1%E6%96%99%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88&url=https%3A%2F%2Fkitekure.sanographix.net&related=sanographix"
            )}
            target="_blank"
            rel="noreferrer"
            onClick={props.onClick}
          >
            <span className={styles.icon}>
              <img src={twitterIcon} alt="Twitter" />
            </span>
            <span className={styles.label}>Pay with tweet</span>
          </a>
        </li>
        <li>
          <a
            href="http://www.facebook.com/sharer.php?u=https%3A%2F%2Fkitekure.sanographix.net"
            target="_blank"
            rel="noreferrer"
            onClick={props.onClick}
          >
            <span className={styles.icon}>
              <img src={facebookIcon} alt="Facebook" />
            </span>
            <span className={styles.label}>Pay with share</span>
          </a>
        </li>
      </ul>
    </>
  );
}

function Thankyou(props) {
  return (
    <>
      <div className={styles.thanksHeading}>Thanks for your support!</div>
      <a
        href="https://sngrpx.gitbook.io/kitekure"
        className={styles.docsLink}
        target="_blank"
        rel="noopener noreferrer"
      >
        Get Started
      </a>
      <div className={styles.back}>
        <button onClick={props.onClick}>← Share again</button>
      </div>
    </>
  );
}

export default Cta;
