import React from "react"
import * as styles from "./styles.module.scss"

import {useTranslation} from "gatsby-plugin-react-i18next"



export default function Faq() {
  const {t} = useTranslation();
  return (
    <div className={styles.background}>

      <div className={styles.container}>
        <section>
          <details className={styles.faq}>
            <summary>{t("FaqWhatsKitekure","Kitekureって何？")}</summary>
            <div className={styles.answer}>
              <p>{t("FaqWhatsKitekureAns","Kitekureは、DJイベント・配信イベントの告知サイトが簡単に作れるテンプレートです。Googleスプレッドシートに必要事項を記入していくと、誰でも高速に告知サイトを完成させられます。")}</p>
            </div>
          </details>
          <details className={styles.faq}>
            <summary>{t("FaqDifferences","Kitekureは他のWebサイトビルダーとどう違うのですか？")}</summary>
            <div className={styles.answer}>
              <p>{t("FaqDifferencesAns","汎用的なWebサイトビルダーは、自由度の高いサイトが作れる一方、制作にかえって時間がかかってしまうことがあります。Kitekureは、はじめからイベント告知に最適化されたサイトを効率的に構築できるよう設計されています。")}</p>
            </div>
          </details>

          <details className={styles.faq}>
            <summary>{t("FaqSkills","Webデザインの知識が必要ですか？")}</summary>
            <div className={styles.answer}>
              <p>{t("FaqSkillsAns","いいえ、不要です。Googleスプレッドシートに告知内容を入力するだけで完成するからです。")}</p>
              <p>{t("FaqSkillsAns2","なお、画像または動画を用意いただくことで、よりきれいに仕上がります。")}</p>
            </div>
          </details>

          <details className={styles.faq}>
            <summary>{t("FaqFree","本当に無料ですか？")}</summary>
            <div className={styles.answer}>
              <p>{t("FaqFreeAns","はい、無料です。加えて、無料のホスティングサービスを利用することで、サイトの公開まで無料で行うことができます。")}</p>
            </div>
          </details>

          <details className={styles.faq}>
            <summary>{t("FaqWhyFree","どうして無料なんですか？ 罠があったりしませんか？")}</summary>
            <div className={styles.answer}>
              <p>{t("FaqWhyFreeAns","趣味で開発しているプロダクトだからです。")}</p>
            </div>
          </details>

          <details className={styles.faq}>
            <summary>{t("FaqWhySpreadsheet","どうしてGoogleスプレッドシートを使うのですか？")}</summary>
            <div className={styles.answer}>
              <p>{t("FaqWhySpreadsheetAns","Googleスプレッドシートが、「使うのが簡単なこと」「無料であること」「開発の容易さ」の3つの観点で優れているからです。")}</p>
            </div>
          </details>

          <details className={styles.faq}>
            <summary>{t("FaqSupport","技術的なサポートは受けられますか？")}</summary>
            <div className={styles.answer}>
              <p>{t("FaqSupportAns","原則としてライセンスに則り、無保証・自己責任でのご利用をお願いします。")}</p>
              <p>{t("FaqSupportAns2","ご不明点がございましたら以下のFAQをご覧ください。")}</p>
              <a href="https://sngrpx.gitbook.io/kitekure/misc/faq">FAQ</a>
            </div>
          </details>

          <details className={styles.faq}>
            <summary>{t("FaqLicense","ライセンスは何ですか？")}</summary>
            <div className={styles.answer}>
              <p>{t("FaqLicenseAns","MITライセンスです。")}</p>
            </div>
          </details>

          <details className={styles.faq}>
            <summary>{t("FaqWhyTemplate","どうしてイベント告知用のテンプレートを作ったのですか？")}</summary>
            <div className={styles.answer}>
              <p>{t("FaqWhyTemplateAns","クラブイベントの開催を応援したいというモチベーションからです。詳しくはブログに書きました。")}</p>
              <a href="https://text.sanographix.net/entry/kitekure" target="_blank" rel="noopener noreferrer">Read Blog</a>
            </div>
          </details>

          <details className={styles.faq}>
            <summary>{t("FaqPurpose","DJ・配信以外のイベント告知サイトにも使えますか？")}</summary>
            <div className={styles.answer}>
              <p>{t("FaqPurposeAns","上記以外の使い方は積極的にサポートはしませんが、もし用途が合えばぜひご利用ください。")}</p>
            </div>
          </details>

          <details className={styles.faq}>
            <summary>{t("FaqAuthor","開発者は誰？")}</summary>
            <div className={styles.answer}>
              <p>{t("FaqAuthorAns","初めまして。佐野章核（@sanographix）と申します。Kitekureのような、ある特定の層に向けた実用品を趣味で作っています。プロフィールは以下をご覧ください。")}</p>
              <a href="https://sanographix.net/">sanographix.net</a>
            </div>
          </details>
        </section>
      </div>

    </div>
  )
}
