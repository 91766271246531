// extracted by mini-css-extract-plugin
export var cta = "styles-module--cta--2in_e";
export var gradient = "styles-module--gradient--3_7G4";
export var dropdown = "styles-module--dropdown--3sgsF";
export var fadeIn = "styles-module--fadeIn--Yw_IW";
export var shareHeading = "styles-module--shareHeading--2_yBw";
export var share = "styles-module--share--3O86D";
export var icon = "styles-module--icon--d_XJg";
export var label = "styles-module--label--Dklcd";
export var skip = "styles-module--skip--WIYBv";
export var thanksHeading = "styles-module--thanksHeading--2EHPv";
export var docsLink = "styles-module--docsLink--3UTT_";
export var back = "styles-module--back--2-gv5";