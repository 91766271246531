import React from "react"
import Cta from "../Cta/Cta"
import * as styles from "./styles.module.scss"

const Hero = () => (
  <div className={styles.hero}>

    <div className={styles.inner}>
      <div className={styles.spacer}>

      </div>
      <div className={styles.content}>
        <h1>
          <span>Your<br />Next</span>
          <span>Event<br />Site.</span>
        </h1>
        <div className={styles.ctaWrapper}>
          <Cta />
        </div>
        <div className={styles.update}>
          <a
            href="https://text.sanographix.net/entry/kitekure-v1-1-0"
            className={styles.updateLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <strong>ハッシュタグアップデート！</strong> バージョン1.1.0の更新内容
          </a>
        </div>
      </div>
    </div>

    <div className={styles.marquee}>
      <span className={styles.marqueeText}>&nbsp;Your Next</span>
      <span className={styles.marqueeText}>&nbsp;Your Next</span>
    </div>
    <div className={styles.marqueeBottom}>
      <span className={styles.marqueeText}>&nbsp;Event Site</span>
      <span className={styles.marqueeText}>&nbsp;Event Site</span>
    </div>
  </div>
)

export default Hero
