import React from "react"
import * as styles from "./styles.module.scss"
import {useTranslation} from "gatsby-plugin-react-i18next"

export default function AnnouncementBar() {
  const {t} = useTranslation();
  return (
    <a href="https://illustfolio-lumina.sanographix.net/ja/" className={styles.announcement} target="_blank" rel="noopener">
      <div>
        {t("AnnounceLabel","🌠 新作できました")}:&nbsp;
        <span className={styles.strong}>{t("AnnounceLumina","Illustfolio Lumina - イラストレーターのためのポートフォリオテンプレート 🌠")}</span>
      </div>
    </a>
  )
}
