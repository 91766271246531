import React from "react"
import * as styles from "./styles.module.scss"

export default function SayingItem(props) {
  return (
    <blockquote cite={props.link} className={styles.item}>
      <div className={styles.header}>
        <div className={styles.headerContent}>
          <div className={styles.name}>{props.name}</div>
          <div className={styles.id}>{props.id}</div>
        </div>
        <div>
          <a href={props.link}>
            <img src={props.serviceIcon} className={styles.service} alt={props.service} />
          </a>
        </div>
      </div>
      <p>{props.paragraph}</p>
      <footer className={styles.time}>
        <a href={props.link}>{props.time}</a>
      </footer>
    </blockquote>
  )
}
