// extracted by mini-css-extract-plugin
export var wrapper = "styles-module--wrapper--1zWgH";
export var inner = "styles-module--inner--3ZdvS";
export var heading = "styles-module--heading--2ClBv";
export var container = "styles-module--container--K9wJT";
export var ctaWrapper = "styles-module--ctaWrapper--3ZpTH";
export var marquee = "styles-module--marquee--2ren4";
export var marqueeBottom = "styles-module--marqueeBottom--uqNTB";
export var marqueeText = "styles-module--marqueeText--3rAGI";
export var marqueeReverse = "styles-module--marquee-reverse--2ckn0";
export var readBlog = "styles-module--readBlog--1HjtW";