// extracted by mini-css-extract-plugin
export var hero = "styles-module--hero--3q5nv";
export var inner = "styles-module--inner--2pKBE";
export var spacer = "styles-module--spacer--35B9f";
export var content = "styles-module--content--1DUsD";
export var ctaWrapper = "styles-module--ctaWrapper--HpiBN";
export var marquee = "styles-module--marquee--2gnG-";
export var marqueeBottom = "styles-module--marqueeBottom--25gg9";
export var marqueeText = "styles-module--marqueeText--UVleB";
export var marqueeReverse = "styles-module--marquee-reverse--3g55y";
export var update = "styles-module--update--1lsoM";
export var updateLink = "styles-module--updateLink--35nL9";